<template>
  <div>
    <SecondNavBar />
    <main>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="row" style="margin-bottom:20px;">
              <div class="col-12">
                <h3 class="purple-title">New Client</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card shadow">
                  <div class="card-header">
                    <h5 style="text-align: center;">General Information</h5>
                  </div>
                  <form @submit.prevent="handleSubmit(client)">
                    <div class="card-body">
                      <div class="row" style="margin-bottom:25px;">
                        <label class="col-lg-3" for="streetName"
                          >Street Name:</label
                        >
                        <input
                          id="streetName"
                          class=" col-lg-4 form-control"
                          v-model="client.streetName"
                        />
                      </div>
                      <div class="row" style="margin-bottom:25px;">
                        <label class="col-lg-2" for="referralReason"
                          >Referral Reason:</label
                        >
                        <textarea
                          id="referralReason"
                          class="offset-lg-1 col-lg-8 form-control"
                          v-model="client.referralReason"
                        ></textarea>
                      </div>
                      <div class="row" style="margin-bottom:25px;">
                        <label class="col-lg-2" for="tenantType"
                          >Tenant Type:</label
                        >
                        <select
                          class="offset-lg-1 col-lg-2 form-control"
                          id="tenantType"
                          v-model="client.tenantType"
                        >
                          <option value="owner">Owner</option>
                          <option value="renter">Renter</option>
                          <option value="family">Family</option>
                        </select>
                        <label class="col-lg-3" for="partOfHOA"
                          >Part of Homeowner's Association?</label
                        >
                        <select
                          class="form-control col-lg-2"
                          v-model="client.hasHOA"
                          id="partOfHOA"
                          ><option value="yes">Yes</option
                          ><option value="no">No</option></select
                        >
                      </div>
                      <div class="row" style="margin-bottom:25px;">
                        <label class="col-lg-2" for="housingType"
                          >Housing Type:</label
                        >
                        <select
                          class="offset-lg-1 col-lg-2 form-control"
                          v-model="client.housingType"
                          id="housingType"
                        >
                          <option value="apartment/condo"
                            >Apartment/Condo</option
                          >
                          <option value="singlefamily">Single Family</option>
                          <option value="townhouse/duplex"
                            >Townhouse/Duplex</option
                          >
                          <option value="module/mobile">Modular/Mobile</option>
                        </select>
                        <label class="col-lg-2" for="numberOfFloors"
                          >No. of Floors:</label
                        >
                        <select
                          id="numberOfFloors"
                          class="form-control offset-lg-1 col-lg-2"
                          v-model="client.numberOfFloors"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                      <div class="row">
                        <div class=" col-6 col-lg-3" style="margin-top:10px;">
                          <router-link to="/clients">
                            <button
                              class="btn purple-btn-outline"
                              type="button"
                            >
                              <FontAwesomeIcon
                                icon="arrow-left"
                              />&nbsp;&nbsp;Back
                            </button>
                          </router-link>
                        </div>
                        <div
                          class=" col-6 offset-lg-6 col-lg-3"
                          style="margin-top:10px;"
                        >
                          <button class="btn purple-btn">
                            <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;Create
                            Client
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="vld-parent">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
              ></loading>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SecondNavBar from "../../components/Clients/SecondNavBar";
import { clientService } from "../../_services";
import router from "../../router/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  components: {
    SecondNavBar,
    Loading,
    FontAwesomeIcon,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,

      client: {
        referralReason: "",
        tenantType: "",
        hasHOA: "",
        housingType: "",
        streetName: "",
        numberOfFloors: "",
        gender: "",
        status: "Initial Assessment Started",
      },
    };
  },
  methods: {
    handleSubmit: function(client) {
      this.isLoading = true;
      const thirdPartyIdentifier = this.$route.query.thirdPartyIdentifier;
      client.name = this.firstName + " " + this.lastName;
      //  client.hasHOA = client.hasHOA ? "yes" : "no";
      clientService.createClient(client, thirdPartyIdentifier).then(
        (response) => {
          this.isLoading = false;
          this.$toast.success("New Client Successfully Created!", {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
          var route = `/clients/${response.data.id}`;
          router.push(route).catch((err) => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // But print any other errors to the console
              console.log(err);
            }
          });
        },
        () => {
          this.$toast.error("Failed to create new client.", {
            position: "bottom-center",
            timeout: 5000,
          });
          this.isLoading = false;
        }
      );
    },
  },
};
</script>

<style></style>
